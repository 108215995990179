import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {
  IOpportunityRangeAmount, IOrganizationByCity, IOrganizationBySector,
  IOrganizationConnectionRequestStatistic,
  IStatisticRangeDate,
  IStatistics,
} from '@shared/interfaces/statistics.interface';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {BaseService} from '@shared/services';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {ICounterInfoCardItem} from '@shared/interfaces';
import {IOpportunityWithRelations} from '@opportunity/interfaces';
import {DocumentUtilClass} from '@document/document-util.class';
import {IChartData} from '@shared/components/ui-elements/chart/interfaces/chart-data.interface';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {IOrganization} from '@organization/interfaces';
import {OpportunityCustomStatusEnum, OpportunityStatusEnum} from '@opportunity/enums';
import {OrganizationTypeEnum} from '@organization/enums';

export interface IStatisticModel {
  statistics: IStatistics | null;
}

export namespace StatisticActions {
  /*
  * DASHBOARD GLOBAL
  * */
  export class GetGlobalStatistics {
    static readonly type: string = '[Statistic] Get Global Statistics';
    constructor(public range?: IStatisticRangeDate, public eventId?: string, public companySizeIds?: string[], public stateIds?: string[]) { }
  }

  export class DownloadGlobalStatistics {
    static readonly type: string = '[Statistic] Download Global Statistics';
    constructor(public range?: IStatisticRangeDate, public eventId?: string, public companySizeIds?: string[], public stateIds?: string[]) { }
  }

  export class DownloadPartialOrganizationStatistics {
    static readonly type: string = '[Statistic] Download Partial Organization Statistics';
    constructor(public range?: IStatisticRangeDate, public eventId?: string, public companySizeIds?: string[], public stateIds?: string[]) { }
  }

  export class DownloadPartialOrganizationGovernmentAgencyStatistics {
    static readonly type: string = '[Statistic] Download Partial Organization Government Agency Statistics';
    constructor(public range?: IStatisticRangeDate, public eventId?: string, public companySizeIds?: string[], public stateIds?: string[]) { }
  }

  export class DownloadGlobalOrganizationStatistics {
    static readonly type: string = '[Statistic] Download Global Organization Statistics';
    constructor(public range?: IStatisticRangeDate, public eventId?: string, public companySizeIds?: string[], public stateIds?: string[]) { }
  }

  export class DownloadPartialUserStatistics {
    static readonly type: string = '[Statistic] Download Partial User Statistics';
    constructor(public range?: IStatisticRangeDate, public companySizeIds?: string[], public stateIds?: string[]) { }
  }


  export class DownloadPartialProductStatistics {
    static readonly type: string = '[Statistic] Download Partial Product Statistics';
    constructor(public range?: IStatisticRangeDate, public companySizeIds?: string[], public stateIds?: string[]) { }
  }

  export class DownloadPartialServiceStatistics {
    static readonly type: string = '[Statistic] Download Partial Service Statistics';
    constructor(public range?: IStatisticRangeDate, public companySizeIds?: string[], public stateIds?: string[]) { }
  }

  export class DownloadPartialOpportunityStatistics {
    static readonly type: string = '[Statistic] Download Partial Opportunity Statistics';
    constructor(public range?: IStatisticRangeDate, public companySizeIds?: string[], public stateIds?: string[]) { }
  }

  export class DownloadPartialConnectionStatistics {
    static readonly type: string = '[Statistic] Download Partial Connection Statistics';
    constructor(public range?: IStatisticRangeDate, public eventId?: string, public companySizeIds?: string[], public stateIds?: string[]) { }
  }

  export class DownloadPartialEventStatistics {
    static readonly type: string = '[Statistic] Download Partial Event Statistics';
    constructor(public range?: IStatisticRangeDate, public companySizeIds?: string[], public stateIds?: string[]) { }
  }

  export class DownloadPartialOrganizationConnectionStatistics {
    static readonly type: string = '[Statistic] Download Partial OrganizationConnection Statistics';
    constructor(public range?: IStatisticRangeDate, public eventId?: string, public companySizeIds?: string[], public stateIds?: string[]) { }
  }

  export class DownloadPartialNotFinishedOpportunitiesStatistics {
    static readonly type: string = '[Statistic] Download Partial NotFinishedOpportunities Statistics';
    constructor(public range?: IStatisticRangeDate, public companySizeIds?: string[], public stateIds?: string[]) { }
  }

  export class DownloadPartialOpportunitiesRangeStatistics {
    static readonly type: string = '[Statistic] Download Partial OpportunitiesRange Statistics';
    constructor(public range?: IStatisticRangeDate, public companySizeIds?: string[], public stateIds?: string[]) { }
  }

  export class DownloadPartialOrganizationBySectorStatistics {
    static readonly type: string = '[Statistic] Download Partial OrganizationBySector Statistics';
    constructor(public range?: IStatisticRangeDate, public companySizeIds?: string[], public stateIds?: string[], public organizationType?: OrganizationTypeEnum, public eventId?: string) { }
  }

  export class DownloadPartialOrganizationByCityStatistics {
    static readonly type: string = '[Statistic] Download Partial OrganizationByCity Statistics';
    constructor(public range?: IStatisticRangeDate, public companySizeIds?: string[], public stateIds?: string[], public organizationType?: OrganizationTypeEnum, public eventId?: string) { }
  }

  /*
  * DASHBOARD POR EMPRESA
  * */
  export class GetStatisticsByOrganization {
    static readonly type: string = '[Statistic] Get Statistics By Organization';
    constructor(public organizationId: string, public range?: IStatisticRangeDate) { }
  }

  export class DownloadStatisticsByOrganization {
    static readonly type: string = '[Statistic] Download Statistics By Organization';
    constructor(public organizationId: string, public organizationTradeName: string, public range?: IStatisticRangeDate) { }
  }

  export class DownloadPartialProductStatisticsByOrganization {
    static readonly type: string = '[Statistic] Download Partial Product Statistics By Organization';
    constructor(public organizationId: string, public range?: IStatisticRangeDate) { }
  }

  export class DownloadPartialServiceStatisticsByOrganization {
    static readonly type: string = '[Statistic] Download Partial Service Statistics By Organization';
    constructor(public organizationId: string, public range?: IStatisticRangeDate) { }
  }

  export class DownloadPartialOpportunityStatisticsByOrganization {
    static readonly type: string = '[Statistic] Download Partial Opportunity Statistics By Organization';
    constructor(public organizationId: string, public range?: IStatisticRangeDate) { }
  }

  export class DownloadPartialBudgetRequestStatisticsByOrganization {
    static readonly type: string = '[Statistic] Download Partial Budget Request Statistics By Organization';
    constructor(public organizationId: string, public range?: IStatisticRangeDate) { }
  }

  export class DownloadPartialConnectionStatisticsByOrganization {
    static readonly type: string = '[Statistic] Download Partial Connection Statistics By Organization';
    constructor(public organizationId: string, public range?: IStatisticRangeDate) { }
  }

  export class DownloadPartialOrganizationConnectionStatisticsByOrganization {
    static readonly type: string = '[Statistic] Download Partial OrganizationConnection Statistics By Organization';
    constructor(public organizationId: string, public range?: IStatisticRangeDate) { }
  }

  /**
   * PROVEEDORES / COMPRADORES
   */
  // TODO: Delete action
  export class DownloadSupplierStatistics {
    static readonly type: string = '[Statistic] Download Suppliers Statistics';
    constructor(public filter?: PrismaFilter<IOrganization>) { }
  }

  // TODO: Delete action
  export class DownloadBuyerStatistics {
    static readonly type: string = '[Statistic] Download Buyers Statistics';
    constructor(public filter?: PrismaFilter<IOrganization>) { }
  }

  export class DownloadOrganizationsStatistics {
    static readonly type: string = '[Statistic] Download Organizations Statistics';
    constructor(public filter?: PrismaFilter<IOrganization>) { }
  }

  export class Reset {
    static readonly type: string = '[Statistic] Clear Statistic';
    constructor() { }
  }
}

const _DEFAULT_DATA: IStatisticModel = {
  statistics: null,
}

@State({
  name: 'StatisticsState',
  defaults: {..._DEFAULT_DATA},
})
@Injectable()
export class StatisticsState {
  private readonly SERVER: string = environment.SERVER;

  constructor(
    private baseService: BaseService,
    private store: Store,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  @Selector()
  static getStatistics({statistics}: IStatisticModel): IStatistics | null {
    return statistics;
  }

  private static calcPercentage(value: number, total: number, maxDecimal: number = 2): number {
    return Number(((value * 100) / (total || 1)).toFixed(maxDecimal));
  }

  @Selector()
  static getOrganizationConnectionRequestStatistics({statistics}: IStatisticModel): IOrganizationConnectionRequestStatistic[] {
    if (!statistics) return [];
    const {organizationConnectionRequest} = statistics;
    return organizationConnectionRequest;
  }

  @Selector()
  static getOrganizationDependencyStatistics({statistics}: IStatisticModel): ICounterInfoCardItem[] {
    if (!statistics) return [];
    const {organizationDependency} = statistics;
    if (!organizationDependency) return [];
    return [
      {
        title: 'Totales',
        value: organizationDependency.total,
        icon: 'la-sitemap',
        percentage: 100,
        message: 'de dependencias',
        color: 'text-sky-500',
        iconBgColor: 'bg-sky-500/10',
      },
      {
        title: 'Compradores',
        value: organizationDependency.buyers,
        percentage: StatisticsState.calcPercentage(organizationDependency.buyers, organizationDependency.total),
        icon: 'la-user-shield',
        message: 'de dependencias',
        color: 'text-emerald-500',
        iconBgColor: 'bg-emerald-500/10',
      },
      {
        title: 'Proveedores',
        value: organizationDependency.suppliers,
        percentage: StatisticsState.calcPercentage(organizationDependency.suppliers, organizationDependency.total),
        icon: 'la-boxes',
        message: 'de dependencias',
        color: 'text-red-500',
        iconBgColor: 'bg-red-500/10',
      },
      {
        title: 'Comprador/Proveedor',
        value: organizationDependency.both,
        percentage: StatisticsState.calcPercentage(organizationDependency.both, organizationDependency.total),
        icon: 'la-truck-loading',
        message: 'de dependencias',
        color: 'text-blue-500',
        iconBgColor: 'bg-blue-500/10',
      },
    ];
  }

  @Selector()
  static getOrganizationDependencyChartStatistics({statistics}: IStatisticModel): {hasSomeValue: boolean, value: ICounterInfoCardItem[]} {
    if (!statistics) return {hasSomeValue: false, value: []};
    const {organizationDependency} = statistics;
    if (!organizationDependency) return {hasSomeValue: false, value: []};
    return {
      hasSomeValue: organizationDependency.total > 0,
      value: [
        {
          title: 'Totales',
          value: organizationDependency.total,
          icon: 'la-sitemap',
          percentage: 100,
          message: 'de dependencias',
          color: 'text-sky-500',
          iconBgColor: 'bg-sky-500/10',
        },
        {
          title: 'Compradores',
          value: organizationDependency.buyers,
          percentage: StatisticsState.calcPercentage(organizationDependency.buyers, organizationDependency.total),
          icon: 'la-user-shield',
          message: 'de dependencias',
          color: 'text-emerald-500',
          iconBgColor: 'bg-emerald-500/10',
        },
        {
          title: 'Proveedores',
          value: organizationDependency.suppliers,
          percentage: StatisticsState.calcPercentage(organizationDependency.suppliers, organizationDependency.total),
          icon: 'la-boxes',
          message: 'de dependencias',
          color: 'text-red-500',
          iconBgColor: 'bg-red-500/10',
        },
        {
          title: 'Comprador/Proveedor',
          value: organizationDependency.both,
          percentage: StatisticsState.calcPercentage(organizationDependency.both, organizationDependency.total),
          icon: 'la-truck-loading',
          message: 'de dependencias',
          color: 'text-blue-500',
          iconBgColor: 'bg-blue-500/10',
        },
      ]
    }
  }

  @Selector()
  static getOpportunityStatistics({statistics}: IStatisticModel): {title: string | undefined, divider: boolean, value: ICounterInfoCardItem[]}[] {
    if (!statistics) return [];
    const {opportunity, opportunityAmount} = statistics;
    if (!opportunity) return [];
    return [
      {
        title: undefined,
        divider: true,
        value: [
          {
            title: 'Creadas',
            value: opportunity.total,
            icon: 'la-handshake',
            percentage: 100,
            message: 'de oportunidades',
            color: 'text-sky-500',
            iconBgColor: 'bg-sky-500/10',
            tooltip: 'Total de oportunidades',
            metadata: {
              allFilters: true,
            }
          },
          {
            title: 'Total',
            value: opportunity.total,
            icon: 'la-money-bill-alt',
            percentage: 0,
            message: 'Monto total de las oportunidades',
            color: 'text-lime-500',
            iconBgColor: 'bg-lime-500/10',
            tooltip: 'Monto total de las oportunidades',
            metadata: {
              isAmountCard: true,
              data: opportunityAmount.total
            }
          }
        ]
      },
      {
        title: 'Oportunidades - Búsqueda',
        divider: false,
        value: [
          {
            title: 'En búsqueda',
            value: opportunity.inSearch,
            icon: 'la-search-location',
            percentage: StatisticsState.calcPercentage(opportunity.inSearch, opportunity.total),
            message: 'de oportunidades',
            color: 'text-orange-500',
            iconBgColor: 'bg-orange-500/10',
            tooltip: 'Total de oportunidades en búsqueda',
            metadata: {
              searchStatuses: [OpportunityStatusEnum.IN_SEARCH_STATE, OpportunityStatusEnum.IN_SEARCH_NATIONAL, OpportunityStatusEnum.NOT_FOUND_LAST_WAIT, OpportunityStatusEnum.COMPLETED_NOT_FOUND]
            }
          },
        ]
      },
      {
        title: undefined,
        divider: false,
        value: [
          {
            title: 'En búsqueda estatal',
            value: opportunity.inSearchState,
            icon: 'la-map-marker-alt',
            percentage: StatisticsState.calcPercentage(opportunity.inSearchState, opportunity.inSearch),
            message: 'de oportunidades',
            color: 'text-yellow-500',
            iconBgColor: 'bg-yellow-500/10',
            tooltip: 'Total de oportunidades en búsqueda estatal',
            metadata: {
              searchStatuses: [OpportunityStatusEnum.IN_SEARCH_STATE]
            }
          },
          {
            title: 'En búsqueda nacional',
            value: opportunity.inSearchNational,
            icon: 'la-map-marked-alt',
            percentage: StatisticsState.calcPercentage(opportunity.inSearchNational, opportunity.inSearch),
            message: 'de oportunidades',
            color: 'text-amber-500',
            iconBgColor: 'bg-amber-500/10',
            tooltip: 'Total de oportunidades en búsqueda nacional',
            metadata: {
              searchStatuses: [OpportunityStatusEnum.IN_SEARCH_NATIONAL]
            }
          },
          {
            title: 'En consejo',
            value: opportunity.inSearchOther,
            icon: 'la-users',
            percentage: StatisticsState.calcPercentage(opportunity.inSearchOther, opportunity.inSearch),
            message: 'de oportunidades',
            color: 'text-orange-500',
            iconBgColor: 'bg-orange-300/10',
            tooltip: 'Total de oportunidades en consejo',
            metadata: {
              searchStatuses: [OpportunityStatusEnum.NOT_FOUND_LAST_WAIT, OpportunityStatusEnum.COMPLETED_NOT_FOUND]
            }
          },
        ]
      },
      {
        title: 'Resultados IA + Postulaciones',
        divider: true,
        value: [
          {
            title: 'Con resultados',
            description: 'Cualquier búsqueda que tenga resultados de la IA o postulaciones',
            value: opportunity.inSearchWithResults,
            icon: 'la-thumbs-up',
            percentage: StatisticsState.calcPercentage(opportunity.inSearchWithResults, opportunity.inSearch),
            message: 'de oportunidades',
            color: 'text-green-500',
            iconBgColor: 'bg-green-500/10',
            tooltip: 'Total de oportunidades en búsqueda con resultados',
            metadata: {
              opportunityCustomStatuses: [OpportunityCustomStatusEnum.IN_SEARCH_WITH_RESULTS]
            }
          },
          {
            title: 'Sin resultados',
            description: 'Cualquier búsqueda que no tenga resultados de la IA o postulaciones',
            value: opportunity.inSearchWithoutResults,
            icon: 'la-thumbs-down',
            percentage: StatisticsState.calcPercentage(opportunity.inSearchWithoutResults, opportunity.inSearch),
            message: 'de oportunidades',
            color: 'text-red-500',
            iconBgColor: 'bg-red-500/10',
            tooltip: 'Total de oportunidades en búsqueda sin resultados',
            metadata: {
              opportunityCustomStatuses: [OpportunityCustomStatusEnum.IN_SEARCH_WITHOUT_RESULTS]
            }
          }
        ]
      },
      {
        title: 'Oportunidades - Ciclo de búsqueda finalizado',
        divider: false,
        value: [{
          title: 'Total',
          value: opportunity.completed,
          icon: 'la-check-circle',
          percentage: StatisticsState.calcPercentage(opportunity.completed, opportunity.total),
          message: 'de oportunidades',
          color: 'text-blue-500',
          iconBgColor: 'bg-blue-500/10',
          tooltip: 'Total de oportunidades con ciclo de búsqueda finalizado',
          metadata: {
            searchStatuses: [OpportunityStatusEnum.COMPLETED_SUCCESSFUL, OpportunityStatusEnum.COMPLETED_BY_USER]
          }
        }]
      },
      {
        title: undefined,
        divider: false,
        value: [
          {
          title: 'En espera de respuesta por usuario',
          value: opportunity.completedSuccessful,
          icon: 'la-check-double',
          percentage: StatisticsState.calcPercentage(opportunity.completedSuccessful, opportunity.completed),
          message: 'de oportunidades',
          color: 'text-indigo-500',
          iconBgColor: 'bg-indigo-500/10',
          tooltip: 'Total de oportunidades con ciclo de búsqueda finalizado, hay resultados y se esta en espera de que seleccione a los proveedores con los que va a trabajar',
          metadata: {
            searchStatuses: [OpportunityStatusEnum.COMPLETED_SUCCESSFUL]
          }
        },
          {
            title: 'Finalizadas por el usuario',
            value: opportunity.completedByUser,
            icon: 'la-user-check',
            percentage: StatisticsState.calcPercentage(opportunity.completedByUser, opportunity.completed),
            message: 'de oportunidades',
            color: 'text-violet-500',
            iconBgColor: 'bg-violet-500/10',
            tooltip: 'Total de oportunidades con ciclo de búsqueda finalizado por el usuario',
            metadata: {
              searchStatuses: [OpportunityStatusEnum.COMPLETED_BY_USER]
            }
          }]
      },
      {
        title: undefined,
        divider: false,
        value: [
          {
            title: 'Finalizadas por el usuario con proveedor',
            value: opportunity.completedByUserWithResolution,
            icon: 'la-thumbs-up',
            percentage: StatisticsState.calcPercentage(opportunity.completedByUserWithResolution, opportunity.completedByUser),
            message: 'de oportunidades',
            color: 'text-green-500',
            iconBgColor: 'bg-green-500/10',
            tooltip: 'Total de oportunidades con ciclo de búsqueda finalizado por el usuario con proveedor',
            metadata: {
              opportunityCustomStatuses: [OpportunityCustomStatusEnum.COMPLETED_BY_USER_WITH_RESOLUTION]
            }
          },
          {
            title: 'Finalizadas por el usuario sin proveedor',
            value: opportunity.completedByUserWithoutResolution,
            icon: 'la-thumbs-down',
            percentage: StatisticsState.calcPercentage(opportunity.completedByUserWithoutResolution, opportunity.completedByUser),
            message: 'de oportunidades',
            color: 'text-red-500',
            iconBgColor: 'bg-red-500/10',
            tooltip: 'Total de oportunidades con ciclo de búsqueda finalizado por el usuario sin proveedor',
            metadata: {
              opportunityCustomStatuses: [OpportunityCustomStatusEnum.COMPLETED_BY_USER_WITHOUT_RESOLUTION]
            }
          },
        ]
      }
    ];
  }

  @Selector()
  static getBudgetRequestStatistics({statistics}: IStatisticModel): ICounterInfoCardItem[] {
    if (!statistics) return [];
    const {budgetRequests} = statistics;
    if (!budgetRequests) return [];
    return [
      {
        title: 'Postulaciones',
        value: budgetRequests.joinOpportunities,
        icon: 'la-hand-pointer',
        percentage: undefined,
        message: 'Hechas por la empresa',
        color: 'text-sky-500',
        iconBgColor: 'bg-sky-500/10',
        tooltip: 'Total de postulaciones que ha hecho la empresa a oportunidades'
      },
      {
        title: 'Sugerencias del sistema',
        value: budgetRequests.suggestedOpportunities,
        icon: 'la-star',
        percentage: undefined,
        message: 'El sistema ha sugerido al proveedor',
        color: 'text-sky-500',
        iconBgColor: 'bg-sky-500/10',
        tooltip: 'Total de veces que el sistema ha sugerido al proveedor a oportunidades'
      },
      {
        title: 'Cotizaciones solicitadas',
        value: budgetRequests.budgetRequests,
        percentage: 100,
        icon: 'la-inbox',
        message: 'cotizaciones solicitadas',
        color: 'text-emerald-500',
        iconBgColor: 'bg-emerald-500/10',
        tooltip: 'Total de solicitudes de cotización recibidas por la empresa'
      },
      {
        title: 'Cotizaciones enviadas',
        value: budgetRequests.budgetRequestAccepted,
        percentage: StatisticsState.calcPercentage(budgetRequests.budgetRequestAccepted, budgetRequests.budgetRequests),
        icon: 'la-paper-plane',
        message: 'cotizaciones enviadas',
        color: 'text-blue-500',
        iconBgColor: 'bg-blue-500/10',
        tooltip: 'Cotizaciones enviadas por la empresa'
      },
      {
        title: 'Cotizaciones pendientes',
        value: budgetRequests.budgetRequestPending,
        percentage: StatisticsState.calcPercentage(budgetRequests.budgetRequestPending, budgetRequests.budgetRequests),
        icon: 'la-spinner',
        message: 'cotizaciones pendientes',
        color: 'text-orange-500',
        iconBgColor: 'bg-orange-500/10',
        tooltip: 'Solicitudes de cotización que no han sido respondidas o rechazadas por la empresa'
      },
      {
        title: 'Cotizaciones rechazadas',
        value: budgetRequests.budgetRequestRejected,
        percentage: StatisticsState.calcPercentage(budgetRequests.budgetRequestRejected, budgetRequests.budgetRequests),
        icon: 'la-user-slash',
        message: 'cotizaciones rechazadas',
        color: 'text-red-500',
        iconBgColor: 'bg-red-500/10',
        tooltip: 'Solicitudes de cotización que han sido rechazadas por la empresa'
      },
    ];
  }

  @Selector()
  static getConnectionRequestStatistics({statistics}: IStatisticModel): ICounterInfoCardItem[] {
    if (!statistics) return [];
    const {connectionRequest} = statistics;
    if (!connectionRequest) return [];

    return [
      {
        title: 'Registradas',
        value: connectionRequest.created,
        icon: 'la-network-wired',
        // percentage: 100,
        message: 'Conexiones registradas',
        color: 'text-sky-500',
        iconBgColor: 'bg-sky-500/10',
        tooltip: 'Total de conexiones registradas'
      },
      {
        title: 'Finalizadas',
        value: connectionRequest.finished,
        // percentage: StatisticsState.calcPercentage(connectionRequest.finished, connectionRequest.created),
        icon: 'la-list-alt',
        message: 'Conexiones finalizadas',
        color: 'text-emerald-500',
        iconBgColor: 'bg-emerald-500/10',
        tooltip: 'Conexiones finalizadas'
      },
      {
        title: 'Finalizadas con retroalimentación',
        value: connectionRequest.finishedWithFeedback,
        percentage: StatisticsState.calcPercentage(connectionRequest.finishedWithFeedback, connectionRequest.finished),
        icon: 'la-quote-left',
        message: 'de conexiones finalizadas con retroalimentación',
        color: 'text-blue-500',
        iconBgColor: 'bg-blue-500/10',
        tooltip: 'Conexiones que han finalizado y los participantes han agregado retroalimentación'
      },
      {
        title: 'Canceladas',
        value: connectionRequest.canceled,
        // percentage: StatisticsState.calcPercentage(connectionRequest.canceled, connectionRequest.created),
        icon: 'la-times-circle',
        message: 'Conexiones canceladas',
        color: 'text-red-500',
        iconBgColor: 'bg-red-500/10',
        tooltip: 'Conexiones canceladas'
      },
      {
        title: 'Con fecha aceptada',
        value: connectionRequest.dateAccepted,
        // percentage: StatisticsState.calcPercentage(connectionRequest.dateAccepted, connectionRequest.created),
        icon: 'la-pencil-ruler',
        message: 'Conexiones aceptadas',
        color: 'text-orange-500',
        iconBgColor: 'bg-orange-500/10',
        tooltip: 'Conexiones que se encuentran en planeación con una fecha aceptada',
      },
      {
        title: 'En planeación sin fecha aceptada',
        value: connectionRequest.withoutDateAccepted,
        // percentage: StatisticsState.calcPercentage(connectionRequest.withoutDateAccepted, connectionRequest.dateAccepted),
        icon: 'la-pencil-ruler',
        message: 'Conexiones sin fecha aceptada',
        color: 'text-amber-500',
        iconBgColor: 'bg-amber-500/10',
        tooltip: 'Conexiones que se encuentran en planeación sin una fecha aceptada',
      },
    ];
  }

  @Selector()
  static getConnectionRequestEventStatistics({statistics}: IStatisticModel): ICounterInfoCardItem[] {
    if (!statistics) return [];
    const {connectionRequest} = statistics;
    if (!connectionRequest) return [];

    return [
      {
        title: 'Registradas',
        value: connectionRequest.createdInEvent ?? 0,
        icon: 'la-network-wired',
        // percentage: 100,
        message: 'Conexiones registradas',
        color: 'text-sky-500',
        iconBgColor: 'bg-sky-500/10',
        tooltip: 'Total de conexiones registradas'
      },
      /*{
        title: 'Registradas durante el evento',
        value: connectionRequest.createdInEvent,
        icon: 'la-calendar-check',
        percentage: 100,
        message: 'de conexiones',
        color: 'text-purple-500',
        iconBgColor: 'bg-purple-500/10',
        tooltip: 'Total de conexiones registradas mientras se llevó a cabo el evento'
      },*/
      {
        title: 'Finalizadas',
        value: connectionRequest.finishedInEvent ?? 0,
        // percentage: StatisticsState.calcPercentage(connectionRequest.finished, connectionRequest.created),
        icon: 'la-list-alt',
        message: 'Conexiones finalizadas',
        color: 'text-emerald-500',
        iconBgColor: 'bg-emerald-500/10',
        tooltip: 'Conexiones finalizadas'
      },
      {
        title: 'Finalizadas con retroalimentación',
        value: connectionRequest.finishedInEventWithFeedback ?? 0,
        percentage: StatisticsState.calcPercentage(connectionRequest.finishedInEventWithFeedback ?? 0, connectionRequest.finishedInEvent ?? 0),
        icon: 'la-quote-left',
        message: 'de conexiones finalizadas con retroalimentación',
        color: 'text-blue-500',
        iconBgColor: 'bg-blue-500/10',
        tooltip: 'Conexiones que han finalizado y los participantes han agregado retroalimentación'
      },
      {
        title: 'Canceladas',
        value: connectionRequest.canceledInEvent ?? 0,
        // percentage: StatisticsState.calcPercentage(connectionRequest.canceled, connectionRequest.created),
        icon: 'la-times-circle',
        message: 'Conexiones canceladas',
        color: 'text-red-500',
        iconBgColor: 'bg-red-500/10',
        tooltip: 'Conexiones canceladas'
      },
      {
        title: 'Con fecha aceptada',
        value: connectionRequest.dateAcceptedInEvent ?? 0,
        // percentage: StatisticsState.calcPercentage(connectionRequest.dateAccepted, connectionRequest.created),
        icon: 'la-pencil-ruler',
        message: 'Conexiones aceptadas',
        color: 'text-orange-500',
        iconBgColor: 'bg-orange-500/10',
        tooltip: 'Conexiones que se encuentran en planeación con una fecha aceptada',
      },
      {
        title: 'En planeación sin fecha aceptada',
        value: connectionRequest.withoutDateAcceptedInEvent ?? 0,
        // percentage: StatisticsState.calcPercentage(connectionRequest.withoutDateAccepted, connectionRequest.dateAccepted),
        icon: 'la-pencil-ruler',
        message: 'Conexiones sin fecha aceptada',
        color: 'text-amber-500',
        iconBgColor: 'bg-amber-500/10',
        tooltip: 'Conexiones que se encuentran en planeación sin una fecha aceptada',
      },
    ];
  }

  @Selector()
  static getEventStatistics({statistics}: IStatisticModel): ICounterInfoCardItem[] {
    if (!statistics) return [];
    const {events} = statistics;
    if (!events) return [];
    return [
      {
        title: 'Totales',
        value: events.total,
        icon: 'la-calendar-alt',
        percentage: 100,
        message: 'de eventos',
        color: 'text-sky-500',
        iconBgColor: 'bg-sky-500/10',
        tooltip: 'Total de eventos',
      },
      {
        title: 'Publicados',
        value: events.published,
        percentage: StatisticsState.calcPercentage(events.published, events.total),
        icon: 'la-toggle-on',
        message: 'de eventos',
        color: 'text-emerald-500',
        iconBgColor: 'bg-emerald-500/10',
        tooltip: 'Eventos publicados',
      },
      {
        title: 'Borradores',
        value: events.draft,
        percentage: StatisticsState.calcPercentage(events.draft, events.total),
        icon: 'la-toggle-off',
        message: 'de eventos',
        color: 'text-orange-500',
        iconBgColor: 'bg-orange-500/10',
        tooltip: 'Eventos en borrador',
      },
    ];
  }

  @Selector()
  static getProductStatistics({statistics}: IStatisticModel): ICounterInfoCardItem[] {
    if (!statistics) return [];
    const {products} = statistics;
    if (!products) return [];
    return [
      {
        title: 'Totales',
        value: products.total,
        icon: 'la-apple-alt',
        percentage: 100,
        message: 'de productos',
        color: 'text-sky-500',
        iconBgColor: 'bg-sky-500/10',
        tooltip: 'Total de productos',
      },
      {
        title: 'Publicados',
        value: products.published,
        percentage: StatisticsState.calcPercentage(products.published, products.total),
        icon: 'la-toggle-on',
        message: 'de productos',
        color: 'text-emerald-500',
        iconBgColor: 'bg-emerald-500/10',
        tooltip: 'Productos que son visibles para las empresas compradoras',
      },
      {
        title: 'Borradores',
        value: products.draft,
        percentage: StatisticsState.calcPercentage(products.draft, products.total),
        icon: 'la-toggle-off',
        message: 'de productos',
        color: 'text-orange-500',
        iconBgColor: 'bg-red-500/10',
        tooltip: 'Productos aún no visibles para las empresas compradoras',
      },
      {
        title: 'Rechazados',
        value: products.rejected,
        percentage: StatisticsState.calcPercentage(products.rejected, products.total),
        icon: 'la-times-circle',
        message: 'de productos',
        color: 'text-red-500',
        iconBgColor: 'bg-orange-500/10',
        tooltip: 'Productos rechazados por los administradores',
      },
    ];
  }

  @Selector()
  static getServiceStatistics({statistics}: IStatisticModel): ICounterInfoCardItem[] {
    if (!statistics) return [];
    const {services} = statistics;
    if (!services) return [];
    return [
      {
        title: 'Totales',
        value: services.total,
        icon: 'la-hand-holding-heart',
        percentage: 100,
        message: 'de servicios',
        color: 'text-sky-500',
        iconBgColor: 'bg-sky-500/10',
        tooltip: 'Total de servicios',
      },
      {
        title: 'Publicados',
        value: services.published,
        percentage: StatisticsState.calcPercentage(services.published, services.total),
        icon: 'la-toggle-on',
        message: 'de servicios',
        color: 'text-emerald-500',
        iconBgColor: 'bg-emerald-500/10',
        tooltip: 'Servicios que son visibles para las empresas compradoras',
      },
      {
        title: 'Borradores',
        value: services.draft,
        percentage: StatisticsState.calcPercentage(services.draft, services.total),
        icon: 'la-toggle-off',
        message: 'de servicios',
        color: 'text-orange-500',
        iconBgColor: 'bg-red-500/10',
        tooltip: 'Servicios aún no visibles para las empresas compradoras',
      },
      {
        title: 'Rechazados',
        value: services.rejected,
        percentage: StatisticsState.calcPercentage(services.rejected, services.total),
        icon: 'la-times-circle',
        message: 'de servicios',
        color: 'text-red-500',
        iconBgColor: 'bg-orange-500/10',
        tooltip: 'Servicios rechazados por los administradores',
      },
    ];
  }

  @Selector()
  static getUserStatistics({statistics}: IStatisticModel): ICounterInfoCardItem[] {
    if (!statistics) return [];
    const {users} = statistics;
    if (!users) return [];
    return [
      {
        title: 'Totales',
        value: users.total,
        icon: 'la-users',
        percentage: 100,
        message: 'de servicios',
        color: 'text-sky-500',
        iconBgColor: 'bg-sky-500/10',
      },
      {
        title: 'Con empresa',
        value: users.users,
        percentage: StatisticsState.calcPercentage(users.users, users.total),
        icon: 'la-building',
        message: 'de servicios',
        color: 'text-emerald-500',
        iconBgColor: 'bg-emerald-500/10',
      },
      {
        title: 'Sin empresa',
        value: users.withoutOrganization,
        percentage: StatisticsState.calcPercentage(users.withoutOrganization, users.total),
        icon: 'la-ban',
        message: 'de servicios',
        color: 'text-red-500',
        iconBgColor: 'bg-red-500/10',
      },
      {
        title: 'Administradores',
        value: users.admins,
        percentage: StatisticsState.calcPercentage(users.admins, users.total),
        icon: 'la-users-cog',
        message: 'de servicios',
        color: 'text-orange-500',
        iconBgColor: 'bg-orange-500/10',
      },
    ];
  }

  @Selector()
  static getCollaboratorStatistics({statistics}: IStatisticModel): ICounterInfoCardItem[] {
    if (!statistics) return [];
    const {collaborators} = statistics;
    if (!collaborators) return [];
    return [
      {
        title: 'Totales',
        value: collaborators.total,
        icon: 'la-users',
        percentage: 100,
        message: 'de colaboradores',
        color: 'text-sky-500',
        iconBgColor: 'bg-sky-500/10',
      },
      {
        title: 'Hombres',
        value: collaborators.man,
        percentage: StatisticsState.calcPercentage(collaborators.man, collaborators.total),
        icon: 'la-mars',
        message: 'de colaboradores',
        color: 'text-emerald-500',
        iconBgColor: 'bg-emerald-500/10',
      },
      {
        title: 'Mujeres',
        value: collaborators.woman,
        percentage: StatisticsState.calcPercentage(collaborators.woman, collaborators.total),
        icon: 'la-venus',
        message: 'de colaboradores',
        color: 'text-pink-500',
        iconBgColor: 'bg-pink-500/10',
      },
      {
        title: 'Otros',
        value: collaborators.others,
        percentage: StatisticsState.calcPercentage(collaborators.others, collaborators.total),
        icon: 'la-transgender',
        message: 'de colaboradores',
        color: 'text-orange-500',
        iconBgColor: 'bg-orange-500/10',
      },
    ];
  }

  @Selector()
  static getOrganizationTypeStatistics({statistics}: IStatisticModel): IChartData[] {
    if (!statistics) return [];
    const {organization} = statistics;
    if (!organization) return [];
    return [
      {
        name: 'Proveedores',
        value: organization.suppliers,
      },
      {
        name: 'Compradores',
        value: organization.buyers,
      },
      {
        name: 'Proveedores/Compradores',
        value: organization.both,
      },
    ];
  }

  @Selector()
  static getOrganizationCreatedInEventTypeStatistics({statistics}: IStatisticModel): IChartData[] {
    if (!statistics) return [];
    const {organizationCreatedInEvent} = statistics;
    if (!organizationCreatedInEvent) return [];
    return [
      {
        name: 'Proveedores',
        value: organizationCreatedInEvent.suppliers,
      },
      {
        name: 'Compradores',
        value: organizationCreatedInEvent.buyers,
      },
      {
        name: 'Proveedores/Compradores',
        value: organizationCreatedInEvent.both,
      },
    ];
  }

  @Selector()
  static getOrganizationValidationStatistics({statistics}: IStatisticModel): ICounterInfoCardItem[] {
    if (!statistics) return [];
    const {organizationValidation, organization} = statistics;
    if (!organizationValidation) return [];
    return [
      {
        title: 'Aceptadas',
        value: organizationValidation.accepted,
        icon: 'la-hand-holding-heart',
        percentage: 100,
        message: 'de empresas',
        color: 'text-sky-500',
        iconBgColor: 'bg-sky-500/10',
      },
      {
        title: 'Pendientes',
        value: organizationValidation.pending,
        percentage: StatisticsState.calcPercentage(organizationValidation.pending, organization.total),
        icon: 'la-toggle-off',
        message: 'de empresas',
        color: 'text-red-500',
        iconBgColor: 'bg-red-500/10',
      },
      {
        title: 'Rechazadas',
        value: organizationValidation.rejected,
        percentage: StatisticsState.calcPercentage(organizationValidation.rejected, organization.total),
        icon: 'la-calendar-alt',
        message: 'de empresas',
        color: 'text-orange-500',
        iconBgColor: 'bg-orange-500/10',
      },
    ];
  }

  @Selector()
  static getHasOrganizationTypeStatistics({statistics}: IStatisticModel): boolean {
    if (!statistics) return false;
    const {organization} = statistics;
    if (!organization) return false;
    return organization.total > 0;
  }

  @Selector()
  static getHasOrganizationCreatedInEventTypeStatistics({statistics}: IStatisticModel): boolean {
    if (!statistics) return false;
    const {organizationCreatedInEvent} = statistics;
    if (!organizationCreatedInEvent) return false;
    return organizationCreatedInEvent.total > 0;
  }



  @Selector()
  static getOrganizationDocumentStatistics({statistics}: IStatisticModel): ICounterInfoCardItem[] {
    if (!statistics) return [];
    const {organizationDocument} = statistics;
    if (!organizationDocument) return [];
    return [
      {
        title: 'Totales',
        value: organizationDocument.total,
        icon: 'la-file-alt',
        percentage: 100,
        message: 'de documentos',
        color: 'text-sky-500',
        iconBgColor: 'bg-sky-500/10',
      },
      {
        title: 'Aceptados',
        value: organizationDocument.accepted,
        percentage: StatisticsState.calcPercentage(organizationDocument.accepted, organizationDocument.total),
        icon: 'la-check-circle',
        message: 'de documentos',
        color: 'text-emerald-500',
        iconBgColor: 'bg-emerald-500/10',
      },
      {
        title: 'Pendientes',
        value: organizationDocument.pending,
        percentage: StatisticsState.calcPercentage(organizationDocument.pending, organizationDocument.total),
        icon: 'la-spinner',
        message: 'de documentos',
        color: 'text-red-500',
        iconBgColor: 'bg-red-500/10',
      },
      {
        title: 'Rechazados',
        value: organizationDocument.rejected,
        percentage: StatisticsState.calcPercentage(organizationDocument.rejected, organizationDocument.total),
        icon: 'la-times-circle',
        message: 'de documentos',
        color: 'text-orange-500',
        iconBgColor: 'bg-orange-500/10',
      },
    ];
  }

  @Selector()
  static getCouncilOpportunitiesStatistics({statistics}: IStatisticModel): IOpportunityWithRelations[] {
    if (!statistics) return [];
    return statistics.councilOpportunities;
  }

  @Selector()
  static getProductServicesPublishedStatistics({statistics}: IStatisticModel): {data: IChartData[], hasSomeValue: boolean} {
    if (!statistics || !statistics.products) return {
      data: [],
      hasSomeValue: false
    };
    return {
      data: [
        {
          name: 'Productos',
          value: statistics.products?.published ?? 0
        },
        {
          name: 'Servicios',
          value: statistics.services?.published ?? 0
        }
      ],
      hasSomeValue: !!(statistics.products.published || statistics.services.published)
    };
  }

  @Selector()
  static getOpportunitiesInSearchStatistics({statistics}: IStatisticModel): {data: IChartData[], hasSomeValue: boolean} {
    if (!statistics || !statistics.opportunity) return {
      data: [],
      hasSomeValue: false
    };
    return {
      data: [
        {
          name: 'Estatal',
          value: statistics.opportunity?.inSearchState ?? 0
        },
        {
          name: 'Nacional',
          value: statistics.opportunity?.inSearchNational ?? 0
        },
        {
          name: 'En consejo',
          value: statistics.opportunity?.inSearchOther ?? 0
        }
      ],
      hasSomeValue: !!(statistics.opportunity.inSearchState || statistics.opportunity.inSearchNational || statistics.opportunity.inSearchOther)
    };
  }

  @Selector()
  static getSuppliersBySectorStatistics({statistics}: IStatisticModel): {data: IChartData[], hasSomeValue: boolean} {
    if (!statistics) return {
      data: [],
      hasSomeValue: false
    };
    const {suppliers} = statistics;
    if (!suppliers || !suppliers.bySector) return {
      data: [],
      hasSomeValue: false
    };
    return {
      data: suppliers.bySector,
      hasSomeValue: suppliers.bySector.some(item => item.value)
    };
  }

  @Selector()
  static getSuppliersByCityStatistics({statistics}: IStatisticModel): {data: IChartData[], hasSomeValue: boolean} {
    if (!statistics) {
      return {
        data: [],
        hasSomeValue: false
      };
    }
    const {suppliers} = statistics;
    if (!suppliers || !suppliers.byCity) {
      return {
        data: [],
        hasSomeValue: false
      };
    }
    return {
      data: suppliers.byCity,
      hasSomeValue: suppliers.byCity.some(item => item.value)
    };
  }

  @Selector()
  static getBuyersBySectorStatistics({statistics}: IStatisticModel): {data: IChartData[], hasSomeValue: boolean} {
    if (!statistics) return {
      data: [],
      hasSomeValue: false
    };
    const {buyers} = statistics;
    if (!buyers || !buyers.bySector) return {
      data: [],
      hasSomeValue: false
    };
    return {
      data: buyers.bySector,
      hasSomeValue: buyers.bySector.some(item => item.value)
    };
  }

  @Selector()
  static getBuyersByCityStatistics({statistics}: IStatisticModel): {data: IChartData[], hasSomeValue: boolean} {
    if (!statistics) {
      return {
        data: [],
        hasSomeValue: false
      };
    }
    const {buyers} = statistics;
    if (!buyers || !buyers.byCity) {
      return {
        data: [],
        hasSomeValue: false
      };
    }
    return {
      data: buyers.byCity,
      hasSomeValue: buyers.byCity.some(item => item.value)
    };
  }

  @Selector()
  static getOrganizationsBySectorStatistics({statistics}: IStatisticModel): IOrganizationBySector[] {
    if (!statistics) {
      return [];
    }
    const {organizationsBySector} = statistics;
    if (!organizationsBySector) {
      return [];
    }
    return [...organizationsBySector];
  }

  @Selector()
  static getOrganizationsByCityStatistics({statistics}: IStatisticModel): IOrganizationByCity[] {
    if (!statistics) {
      return [];
    }
    const {organizationsByCity} = statistics;
    if (!organizationsByCity) {
      return [];
    }
    return [...organizationsByCity]
  }

  @Selector()
  static getOpportunitiesAmountStatistics({statistics}: IStatisticModel): {data: IChartData[], hasSomeValue: boolean, items: IOpportunityRangeAmount[]} {
    if (!statistics) return {
      data: [],
      hasSomeValue: false,
      items: []
    };
    const {opportunityAmount} = statistics;
    if (!opportunityAmount || !opportunityAmount.ranges) return {
      data: [],
      hasSomeValue: false,
      items: []
    };
    return {
      data: [],
      hasSomeValue: true,
      items: opportunityAmount.ranges
    };
  }

  @Selector()
  static getCapitalizedOpportunitiesAmountStatistics({statistics}: IStatisticModel): {data: IChartData[], hasSomeValue: boolean, items: IOpportunityRangeAmount[]} {
    if (!statistics) return {
      data: [],
      hasSomeValue: false,
      items: []
    };
    const {opportunityAmount} = statistics;
    if (!opportunityAmount || !opportunityAmount.rangesCompleted) return {
      data: [],
      hasSomeValue: false,
      items: []
    };
    return {
      data: [],
      hasSomeValue: true,
      items: opportunityAmount.rangesCompleted
    };
  }

  @Action(StatisticActions.GetGlobalStatistics)
  async getGlobalStatistics({patchState}: StateContext<IStatisticModel>, {range, eventId, companySizeIds, stateIds}: StatisticActions.GetGlobalStatistics): Promise<void> {
    let path: string = `${this.SERVER}/global-statistics`;
    if (eventId) {
      path += `?eventId=${eventId}`;
    }
    const value = {
      range: JSON.stringify(range),
      companySizeIds: JSON.stringify(companySizeIds),
      stateIds: JSON.stringify(stateIds)
    }
    const response: DataBaseServiceResponse<IStatistics> = await firstValueFrom(this.baseService.get(path, void 0, { params: range ? value : {} }))
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({statistics: response.entity});
  }

  @Action(StatisticActions.GetStatisticsByOrganization)
  async getStatisticsByOrganization({patchState}: StateContext<IStatisticModel>, {range, organizationId}: StatisticActions.GetStatisticsByOrganization): Promise<void> {
    const response: DataBaseServiceResponse<IStatistics> = await firstValueFrom(this.baseService.get(`${this.SERVER}/organizations/${organizationId}/statistics`, void 0, { params: range ? { range: JSON.stringify(range) } : {} }))
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({statistics: response.entity});
  }

  @Action(StatisticActions.DownloadStatisticsByOrganization)
  async downloadStatisticsByOrganization(_: StateContext<IStatisticModel>, {range, organizationId, organizationTradeName}: StatisticActions.DownloadStatisticsByOrganization): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/organizations/${organizationId}/statistics/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, organizationTradeName, 'xlsx');
  }

  @Action(StatisticActions.DownloadGlobalStatistics)
  async downloadGlobalStatistics(_: StateContext<IStatisticModel>, {range, eventId, companySizeIds, stateIds}: StatisticActions.DownloadGlobalStatistics): Promise<void> {
    let path: string = `${this.SERVER}/global-statistics/download`;
    if (eventId) {
      path += `?eventId=${eventId}`;
    }
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(path, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range), companySizeIds: JSON.stringify(companySizeIds), stateIds: JSON.stringify(stateIds) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Estadísticas generales', 'xlsx');
  }

  /**
   * Descargar la información parcial de las organizaciones, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param eventId
   * @param companySizeIds
   */
  @Action(StatisticActions.DownloadPartialOrganizationStatistics)
  async downloadPartialOrganizationStatistics(_: StateContext<IStatisticModel>, {range, eventId, companySizeIds, stateIds}: StatisticActions.DownloadPartialOrganizationStatistics): Promise<void> {
    let path: string = `${this.SERVER}/global-statistics/organizations/download`;
    if (eventId) {
      path += `?eventId=${eventId}`;
    }
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(path, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range), companySizeIds: JSON.stringify(companySizeIds), stateIds: JSON.stringify(stateIds) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Listado de organizaciones', 'xlsx');
  }

  /**
   * Descargar la información parcial de las organizaciones gubernamentales, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param eventId
   * @param companySizeIds
   */
  @Action(StatisticActions.DownloadPartialOrganizationGovernmentAgencyStatistics)
  async downloadPartialOrganizationGovernmentAgencyStatistics(_: StateContext<IStatisticModel>, {range, eventId, companySizeIds, stateIds}: StatisticActions.DownloadPartialOrganizationGovernmentAgencyStatistics): Promise<void> {
    let path: string = `${this.SERVER}/global-statistics/organization-government-agencies/download`;
    if (eventId) {
      path += `?eventId=${eventId}`;
    }
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(path, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range), companySizeIds: JSON.stringify(companySizeIds), stateIds: JSON.stringify(stateIds) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Listado de organizaciones gubernamentales', 'xlsx');
  }

  /**
   * Descargar la información de las organizaciones, todos los valores sin filtro de fechas
   * @param _
   * @param eventId
   * @param range
   * @param companySizeIds
   */
  @Action(StatisticActions.DownloadGlobalOrganizationStatistics)
  async downloadGlobalOrganizationStatistics(_: StateContext<IStatisticModel>, {eventId, range, companySizeIds, stateIds}: StatisticActions.DownloadGlobalOrganizationStatistics): Promise<void> {
    let path: string = `${this.SERVER}/global-statistics/organizations/download`;
    if (eventId) {
      path += `?eventId=${eventId}`;
    }
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(path, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range), companySizeIds: JSON.stringify(companySizeIds), stateIds: JSON.stringify(stateIds) } : {} }));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    DocumentUtilClass.download(response.entity!, 'Listado global de organizaciones', 'xlsx');
  }

  /**
   * Descargar la información parcial de los usuarios, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param companySizeIds
   */
  @Action(StatisticActions.DownloadPartialUserStatistics)
  async downloadPartialUserStatistics(_: StateContext<IStatisticModel>, {range, companySizeIds, stateIds}: StatisticActions.DownloadPartialUserStatistics): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/global-statistics/users/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range), companySizeIds: JSON.stringify(companySizeIds), stateIds: JSON.stringify(stateIds) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Listado de usuarios', 'xlsx');
  }

  /**
   * Descargar la información parcial de los productos, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param companySizeIds
   */
  @Action(StatisticActions.DownloadPartialProductStatistics)
  async downloadPartialProductStatistics(_: StateContext<IStatisticModel>, {range, companySizeIds, stateIds}: StatisticActions.DownloadPartialProductStatistics): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/global-statistics/products/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range), companySizeIds: JSON.stringify(companySizeIds), stateIds: JSON.stringify(stateIds) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Listado de productos', 'xlsx');
  }

  /**
   * Descargar la información parcial de los servicios, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param companySizeIds
   * @param stateIds
   */
  @Action(StatisticActions.DownloadPartialServiceStatistics)
  async downloadPartialServiceStatistics(_: StateContext<IStatisticModel>, {range, companySizeIds, stateIds}: StatisticActions.DownloadPartialServiceStatistics): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/global-statistics/services/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range), companySizeIds: JSON.stringify(companySizeIds), stateIds: JSON.stringify(stateIds) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Listado de servicios', 'xlsx');
  }

  /**
   * Descargar la información parcial de las oportunidades, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param companySizeIds
   * @param stateIds
   */
  @Action(StatisticActions.DownloadPartialOpportunityStatistics)
  async downloadPartialOpportunityStatistics(_: StateContext<IStatisticModel>, {range, companySizeIds, stateIds}: StatisticActions.DownloadPartialOpportunityStatistics): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/global-statistics/opportunities/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range), companySizeIds: JSON.stringify(companySizeIds), stateIds: JSON.stringify(stateIds) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Listado de oportunidades', 'xlsx');
  }

  /**
   * Descargar la información parcial de las conexiones, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param eventId
   * @param companySizeIds
   * @param stateIds
   */
  @Action(StatisticActions.DownloadPartialConnectionStatistics)
  async downloadPartialConnectionStatistics(_: StateContext<IStatisticModel>, {range, eventId, companySizeIds, stateIds}: StatisticActions.DownloadPartialConnectionStatistics): Promise<void> {
    let path: string = `${this.SERVER}/global-statistics/connection-requests/download`;
    if (eventId) {
      path += `?eventId=${eventId}`;
    }
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(path, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range), companySizeIds: JSON.stringify(companySizeIds), stateIds: JSON.stringify(stateIds) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Listado de conexiones', 'xlsx');
  }

  /**
   * Descargar la información parcial de los eventos, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param companySizeIds
   * @param stateIds
   */
  @Action(StatisticActions.DownloadPartialEventStatistics)
  async downloadPartialEventStatistics(_: StateContext<IStatisticModel>, {range, companySizeIds, stateIds}: StatisticActions.DownloadPartialEventStatistics): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/global-statistics/events/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range), companySizeIds: JSON.stringify(companySizeIds), stateIds: JSON.stringify(stateIds) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Listado de eventos', 'xlsx');
  }

  /**
   * Descargar la información parcial de las empresas con más conexiones, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param eventId
   * @param companySizeIds
   * @param stateIds
   */
  @Action(StatisticActions.DownloadPartialOrganizationConnectionStatistics)
  async downloadPartialOrganizationConnectionStatistics(_: StateContext<IStatisticModel>, {range, eventId, companySizeIds, stateIds}: StatisticActions.DownloadPartialOrganizationConnectionStatistics): Promise<void> {
    let path: string = `${this.SERVER}/global-statistics/organization-connection-requests/download`;
    if (eventId) {
      path += `?eventId=${eventId}`;
    }
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(path, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range), companySizeIds: JSON.stringify(companySizeIds), stateIds: JSON.stringify(stateIds) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Conexiones de empresas', 'xlsx');
  }

  /**
   * Descargar la información parcial de las oportunidades sin finalizar, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   */
  @Action(StatisticActions.DownloadPartialNotFinishedOpportunitiesStatistics)
  async downloadPartialNotFinishedOpportunitiesStatistics(_: StateContext<IStatisticModel>, {range, companySizeIds, stateIds}: StatisticActions.DownloadPartialNotFinishedOpportunitiesStatistics): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/global-statistics/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range), companySizeIds: JSON.stringify(companySizeIds), stateIds: JSON.stringify(stateIds) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Oportunidades sin finalizar', 'xlsx');
  }

  /**
   * Descargar la información parcial de los montos de las oportunidades, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param companySizeIds
   * @param stateIds
   */
  @Action(StatisticActions.DownloadPartialOpportunitiesRangeStatistics)
  async downloadPartialOpportunitiesRangeStatistics(_: StateContext<IStatisticModel>, {range, companySizeIds, stateIds}: StatisticActions.DownloadPartialOpportunitiesRangeStatistics): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/global-statistics/opportunities/by-amount/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range), companySizeIds: JSON.stringify(companySizeIds), stateIds: JSON.stringify(stateIds) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Monto de las oportunidades', 'xlsx');
  }

  /**
   * Descargar la información parcial de los compradores o proveedores por sector, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param companySizeIds
   * @param stateIds
   * @param type
   * @param eventId
   */
  @Action(StatisticActions.DownloadPartialOrganizationBySectorStatistics)
  async downloadPartialOrganizationBySectorStatistics(_: StateContext<IStatisticModel>, {range, companySizeIds, stateIds, organizationType, eventId}: StatisticActions.DownloadPartialOrganizationBySectorStatistics): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/global-statistics/organizations/by-sector/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, {
      params: {
        range,
        companySizeIds,
        stateIds,
        organizationType,
        eventId
      }
    }));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Empresas por sector', 'xlsx');
  }

  /**
   * Descargar la información parcial de los compradores o proveedores por sector, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param companySizeIds
   * @param stateIds
   * @param type
   */
  @Action(StatisticActions.DownloadPartialOrganizationByCityStatistics)
  async downloadPartialOrganizationByCityStatistics(_: StateContext<IStatisticModel>, {range, companySizeIds, stateIds, organizationType, eventId}: StatisticActions.DownloadPartialOrganizationByCityStatistics): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/global-statistics/organizations/by-city/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, {
      params: {
        range,
        companySizeIds,
        stateIds,
        organizationType,
        eventId
      }
    }));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Empresas por ciudad', 'xlsx');
  }

  /*
  * ACTIONS PARA DASHBOARD DE EMPRESA
  * */

  /**
   * Descargar la información parcial de los productos de una empresa, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param organizationId
   */
  @Action(StatisticActions.DownloadPartialProductStatisticsByOrganization)
  async downloadPartialProductStatisticsByOrganization(_: StateContext<IStatisticModel>, {range, organizationId}: StatisticActions.DownloadPartialProductStatisticsByOrganization): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/organizations/${organizationId}/statistics/products/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Listado de productos', 'xlsx');
  }

  /**
   * Descargar la información parcial de los servicios de una empresa, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param organizationId
   */
  @Action(StatisticActions.DownloadPartialServiceStatisticsByOrganization)
  async downloadPartialServiceStatisticsByOrganization(_: StateContext<IStatisticModel>, {range, organizationId}: StatisticActions.DownloadPartialServiceStatisticsByOrganization): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/organizations/${organizationId}/statistics/services/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Listado de servicios', 'xlsx');
  }

  /**
   * Descargar la información parcial de las oportunidades de una empresa, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param organizationId
   */
  @Action(StatisticActions.DownloadPartialOpportunityStatisticsByOrganization)
  async downloadPartialOpportunityStatisticsByOrganization(_: StateContext<IStatisticModel>, {range, organizationId}: StatisticActions.DownloadPartialOpportunityStatisticsByOrganization): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/organizations/${organizationId}/statistics/opportunities/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Listado de oportunidades', 'xlsx');
  }

  /**
   * Descargar la información parcial de las postulaciones/cotizaciones de una empresa, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param organizationId
   */
  @Action(StatisticActions.DownloadPartialBudgetRequestStatisticsByOrganization)
  async downloadPartialBudgetRequestStatisticsByOrganization(_: StateContext<IStatisticModel>, {range, organizationId}: StatisticActions.DownloadPartialBudgetRequestStatisticsByOrganization): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/organizations/${organizationId}/statistics/budget-requests/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Listado de postulaciones/cotizaciones', 'xlsx');
  }

  /**
   * Descargar la información parcial de las conexiones de una empresa, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param organizationId
   */
  @Action(StatisticActions.DownloadPartialConnectionStatisticsByOrganization)
  async downloadPartialConnectionStatisticsByOrganization(_: StateContext<IStatisticModel>, {range, organizationId}: StatisticActions.DownloadPartialConnectionStatisticsByOrganization): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/organizations/${organizationId}/statistics/connection-requests/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Listado de conexiones', 'xlsx');
  }

  /**
   * Descargar la información de los compradores
   * @param _
   * @param range
   */
  @Action(StatisticActions.DownloadBuyerStatistics)
  async downloadBuyerStatistics(_: StateContext<IStatisticModel>, {filter}: StatisticActions.DownloadBuyerStatistics): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/organizations/buyers/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, void 0, void 0, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Listado de compradores', 'xlsx');
  }

  /**
   * Descargar la información de las organizaciones
   * @param _
   * @param range
   */
  @Action(StatisticActions.DownloadOrganizationsStatistics)
  async downloadOrganizationsStatistics(_: StateContext<IStatisticModel>, {filter}: StatisticActions.DownloadOrganizationsStatistics): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/organizations/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, void 0, void 0, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Listado de empresas', 'xlsx');
  }

  /**
   * Descargar la información de los proveedores
   * @param _
   * @param range
   */
  @Action(StatisticActions.DownloadSupplierStatistics)
  async downloadSupplierStatistics(_: StateContext<IStatisticModel>, {filter}: StatisticActions.DownloadSupplierStatistics): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/organizations/suppliers/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, void 0, void 0, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Listado de proveedores', 'xlsx');
  }

  /**
   * PROVEEDORES / COMPRADORES
   */

  /**
   * Descargar la información parcial de las empresas con más conexiones de una empresa, solo los valores obtenidos por el filtro de fechas
   * @param _
   * @param range
   * @param organizationId
   */
  @Action(StatisticActions.DownloadPartialOrganizationConnectionStatisticsByOrganization)
  async downloadPartialOrganizationConnectionStatisticsByOrganization(_: StateContext<IStatisticModel>, {range, organizationId}: StatisticActions.DownloadPartialOrganizationConnectionStatisticsByOrganization): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/organizations/${organizationId}/statistics/organization-connection-requests/download`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', void 0, { params: range ? { range: JSON.stringify(range) } : {} }, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, 'Conexiones de empresas', 'xlsx');
  }
}
